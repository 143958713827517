import React from 'react';
import style from './Home.module.scss';
import { withRouter, useHistory } from 'react-router-dom';

import barLogo from 'static/logo.png';

function Home() {
    let history = useHistory();

    return (
        <div className={style.wrapper}>
            <img draggable="false" src={barLogo} className={style.Logo} style={{ maxWidth: '60%' }} alt='' />
            <div className={style.content}>
                <h1>Welcome</h1>
                <p>We are not responsible for any actions performed due to the use of DigitalDrinks. Please drink responsibly!</p>
                <div className={style.button} onClick={() => history.push('/matrix')}>
                    <p>Play Matrix</p>
                </div>
            </div>
            <div className={style.footer}>
                <img draggable="false" src={barLogo} className={style.footerImage} alt=''/>
            </div>
        </div>
    );
}

export default withRouter(Home);
