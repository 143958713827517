import React, { Component, Fragment } from 'react';
import style from './Card.module.scss';

//Import Components
import barLogo from 'static/logo.png';
import klaver from 'static/klaver.svg';
import ruiten from 'static/ruiten.svg';
import schoppen from 'static/schoppen.svg';
import harten from 'static/harten.svg';


class Card extends Component {

    constructor() {
        super();

        this.state = {
            hidden: false,
            clickable: false,
            popupOpen: false,
            guess: '',
            guessType: null
        };

        this.hiddenSwitch = this.hiddenSwitch.bind(this);
        this.signToName = this.signToName.bind(this);
        this.openPopup = this.openPopup.bind(this);
        this.handleGuess = this.handleGuess.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.guessQuestions = this.guessQuestions.bind(this);

    }

    componentDidMount() {
        //Close all cards but cross at start
        if (this.props.card.hidden) {
            this.setState({
                hidden: true,
                clickable: true
            });
        }

        this.setState({
            guessType: this.props.guessType
        });

        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.hidden !== this.props.hidden) {
            this.setState({ hidden: this.props.hidden });
            if (this.props.hidden === true) {
                this.setState({
                    clickable: true
                });
            }
        }

        if (prevProps.guessType !== this.props.guessType) {
            this.setState({ guessType: this.props.guessType });
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    /**
   * Set the wrapper ref
   */
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                popupOpen: false
            });
        }
    }

    hiddenSwitch() {
        this.setState({
            hidden: !this.state.hidden
        });
    }

    openPopup() {
        this.props.cardCheck(this.props.index, !this.state.hidden, this.state.guess);
        this.setState({ popupOpen: true });
    }

    handleGuess(guess) {
        this.setState({
            guess: guess
        }, () => {
            this.props.verifyGuess(this.props.index, !this.state.hidden, this.state.guess);
            this.setState({
                popupOpen: false
            });
            this.hiddenSwitch();
        });
    }

    signToName(sign) {
        switch (sign) {
            case 1:
                return <img className={style.symbol} src={harten} alt=""/>;
            case 2:
                return <img className={style.symbol} src={klaver} alt=""/>;
            case 3:
                return <img className={style.symbol} src={ruiten} alt=""/>;
            case 4:
                return <img className={style.symbol} src={schoppen} alt=""/>;
            default:
                return 'error';
        }
    }

    guessQuestions(guessType) {
        switch (guessType) {
            case 1:
                return <Fragment><div className={style.popupTop}>
                    <p>Is the card higher, lower or equal to the adjacent value?</p>
                </div>
                <div className={style.popupContent}>
                    <button type='button' className={style.button} onClick={() => this.handleGuess('hoger')}>Higher</button>
                    <button type='button' className={style.button} onClick={() => this.handleGuess('lager')}>Lower</button>
                    <button type='button' className={style.button} onClick={() => this.handleGuess('paal')}>Equal</button>
                </div>
                </Fragment>;
            case 2:
                return <Fragment>
                    <div className={style.popupTop}>
                        <p>Is the card inside or outside of the adjacent values?</p>
                    </div>
                    <div className={style.popupContent}>
                        <button type='button' className={style.button} onClick={() => this.handleGuess('binnen')}>Inside</button>
                        <button type='button' className={style.button} onClick={() => this.handleGuess('buiten')}>Outside</button>
                        {/* <button type='button' className={style.button} onClick={() => this.handleGuess('paal')}>Pole</button> */}
                    </div>
                </Fragment>;
            case 3:
                return <Fragment><div className={style.popupTop}>
                    <p>Is the card symbol present on the adjacent cards?</p>
                </div>
                <div className={style.popupContent}>
                    <button type='button' className={style.button} onClick={() => this.handleGuess('hebIk')}>Present</button>
                    <button type='button' className={style.button} onClick={() => this.handleGuess('hebIkNiet')}>Not present</button>
                    {/* <button type='button' className={style.button} onClick={() => this.handleGuess('disco')}>Disco</button> */}
                </div>
                </Fragment>;
            case 4:
                return <Fragment><div className={style.popupTop}>
                    <p>Which type of card will appear?</p>
                </div>
                <div className={style.popupContent}>
                    <button type='button' className={style.button} onClick={() => this.handleGuess(1)}>Hearts</button>
                    <button type='button' className={style.button} onClick={() => this.handleGuess(2)}>Spades</button>
                    <button type='button' className={style.button} onClick={() => this.handleGuess(3)}>Diamonds</button>
                    <button type='button' className={style.button} onClick={() => this.handleGuess(4)}>Clubs</button>
                    
                </div>
                </Fragment>;
            default:
                return <Fragment><div className={style.popupTop}>
                    <p>This card cannot be played, dumb sackito</p>
                </div>
                </Fragment>;
        }
    }

    render() {
        // console.log(this.props.clickable)
        var cardColor;
        if (this.props.card.sign === 1 || this.props.card.sign === 3) {
            cardColor ='#D52E21';
        }
        else {
            cardColor = '#131919';
        }
        return (
            <Fragment>
                <div onClick={(this.props.clickable && this.state.clickable && this.state.hidden) ? this.openPopup : null} className={style.card} style={this.props.hidden ? { background: '#131919', opacity: `${this.props.clickable ? 1 : 0.75}`, borderColor: `${this.props.selected ? '#93CC58' : '#131919'}` } : { borderColor: cardColor, opacity: `${this.props.clickable ? 1 : 0.75}` }}>
                    {
                        this.state.popupOpen &&
                    <div className={style.popup} ref={this.setWrapperRef}>
                        {/* <FontAwesomeIcon icon={faTimes} onClick={this.openPopup} style={{ marginRight: '10px', position: 'absolute', left: '5px', top: '5px' }}/> */}
                        {this.guessQuestions(this.props.guessType)}
                        <div className={style.arrowDown}></div>
                    </div>
                    }
                    {this.state.hidden && <img draggable="false" className={style.backImage} src={barLogo} alt=""/>}
                    {  !this.state.hidden && 
                <div className={style.insideCard} style={(this.props.card.sign === 1 || this.props.card.sign === 3) ? { color: '#D52E21' }: {}}>
                    <div className={style.topInside}>
                        {this.props.card.value < 11 && <p>{this.props.card.value}</p>}
                        {this.props.card.value == 14 && <p>A</p>}
                        {this.props.card.value == 13 && <p>K</p>}
                        {this.props.card.value == 12 && <p>Q</p>}
                        {this.props.card.value == 11 && <p>J</p>}
                    </div>
                    <div className={style.middleInside}>
                        <p>{this.signToName(this.props.card.sign)}</p>
                    </div>
                    <div className={style.bottomInside}>
                        {this.props.card.value < 11 && <p>{this.props.card.value}</p>}
                        {this.props.card.value == 14 && <p>A</p>}
                        {this.props.card.value == 13 && <p>K</p>}
                        {this.props.card.value == 12 && <p>Q</p>}
                        {this.props.card.value == 11 && <p>J</p>}
                    </div>
                
                </div>
                    }
                </div>
            </Fragment>
        );
    }
}

export default Card;