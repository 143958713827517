import React, { useState, useEffect } from 'react';
import openSocket from 'socket.io-client';

const socket = openSocket('/Data', {path: '/sockets'});

export default function Data() {

    const [ data, setData ] = useState(0);
    
    useEffect(() => {
        dbstart();
        socket.on('SendAnalytics', data=>{
            console.log(data);
            setData(data);
            // setCurrentGames(data.current);
        });
        
        return () => {
            socket.removeAllListeners();
        };
    },[ setData ]);

    function dbstart() {
        socket.emit('GetAnalytics', 'get the data from the server');
    }

    return (
        <div style={{ alignSelf: 'center', color: 'white' }}>
            <style>{'body {background-color: black }'}</style>
            <h1>Just some numbers about Digital Drinks</h1>
            <h2>Amount of games started all time: </h2>
            <h1 style={{ color: 'yellow' }}>{data.total}</h1>
            <h2>Amount of games currently being played:</h2>
            <h1 style={{ color: 'yellow' }}>{data.current}</h1>
        </div>
    );
}
