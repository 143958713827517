import React, { useEffect, useState, Fragment } from 'react';
import style from './Matrix.module.scss';
import openSocket from 'socket.io-client';
import barLogo from 'static/logo.png';

//Import Components
import Card from 'components/Card/Card';

//Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faInfoCircle, faTimes, faGamepad, faCheckSquare } from '@fortawesome/free-solid-svg-icons';

const color = [ '#D52E21', 'blue', 'green', 'orange', 'brown', 'pink', '#D52E21', 'blue', 'green', 'orange', 'brown', 'pink', '#D52E21', 'blue', 'green', 'orange', 'brown', 'pink', '#D52E21', 'blue', 'green', 'orange', 'brown', 'pink', '#D52E21', 'blue', 'green', 'orange', 'brown', 'pink', '#D52E21', 'blue', 'green', 'orange', 'brown', 'pink', '#D52E21', 'blue', 'green', 'orange', 'brown', 'pink', '#D52E21', 'blue', 'green', 'orange', 'brown', 'pink', '#D52E21', 'blue', 'green', 'orange', 'brown', 'pink', '#D52E21', 'blue', 'green', 'orange', 'brown', 'pink', '#D52E21', 'blue', 'green', 'orange', 'brown', 'pink', '#D52E21', 'blue', 'green', 'orange', 'brown', 'pink' ];

const socket = openSocket('/Matrix', { path:'/sockets' });


function Matrix() {
    const [ allCards, setallCards ] = useState([]);
    const [ guessType, setGuessType ] = useState(null);
    const [guessResult, setGuessresult] = useState(undefined);
    const [ roomId, setRoomId ] = useState('');
    const [sips, setSips] = useState(0);
    const [ tempUser, setTempUser ] = useState({});
    const [ username, setUsername ] = useState('');
    const [ joinedRoom, setJoined ] = useState(false);
    const [ gameStarted, setStarted ] = useState(false);
    const [ users, setUsers ] = useState([]);
    const [ results, setResults ] = useState([]);
    const [ currentCard, setCurrentCard ] = useState();
    const [ resetCards, setResetCards ] = useState([]);
    const [ openInfo, setPopup ] = useState(false);
    const [ clickable, setClickable ] = useState(true);
    const [ currentPlayer, setCurrentPlayer ] = useState({});
    const [ attempts, setAttempts ] = useState(0);


    useEffect(() => {
        socket.removeAllListeners();
        socket.on('joinedRoom', (data) => {
            setUsers(data.users);
        });
        socket.on('reloadPage', () => {
            window.location.reload();
        });
        socket.on('updatePlayers', (data) => {
            setUsers(data.users);
        });
        socket.on('wrongLobby', () => {
            alert('Room does not exist. Check your code or create a room!');
        });
        socket.on('nextTurn', (data) => {
            setCurrentPlayer(data.currentUser);
            checkClickable(data.currentUser.username, username) ? setClickable(true) : setClickable(false);
            setAttempts(0);

        });
        socket.on('attempts', (data) => {
            setAttempts(data.attempts);
        });

        socket.on('verifyGuessReturn', (data) => {
            updateCard(data.testAllCards);
            setTempUser(data.user);
            // setGuessresult(data.verify);
            if (data.verify === true) {
                let newResult = {
                    name: currentPlayer.username,
                    sips: '0 sips'
                };
                let newResultArray = results;
                newResultArray.unshift(newResult);
                if (newResultArray.length > 10) {
                    newResultArray.length = 10;
                }
                setResults(newResultArray);
                checkClickable(currentPlayer.username, username) ? setClickable(true) : setClickable(false);
            }
        });
        socket.on('joinedRoom', () => {
            setJoined(true);
        });
        socket.on('createdRoom', (data) => {
            setRoomId(data.room);
            setJoined(true);
        });
        socket.on('amountOfSips', data => {

            let resetIds = data.resetCards.map(resetCard => resetCard.id);
            setResetCards(resetIds);
            // setSips(data.sips);
            var newResult = {
                name: data.user.username,
                sips: (data.sips + ' sips')
            };
            var newResultArray = results;
            newResultArray.unshift(newResult);
            if (newResultArray.length > 10) {
                newResultArray.length = 10;
            }
            setResults(newResultArray);

        });
        socket.on('typeOfGuess', (data) => {
            setGuessType(data);
        });
        socket.on('updateCards', (data) => {
            setStarted(true);
            updateCard(data.cards);
            checkClickable(currentPlayer.username, username) ? setClickable(true) : setClickable(false);
        });

        socket.on('startingGame', (allCards) => {
            setallCards([]);
            setallCards(allCards);
            setStarted(true);
        });

        socket.on('selectedCard', (data) => {
            setCurrentCard(data.data.index);
        });
        return () => {
            socket.removeAllListeners();

        };
    });


    function startGame() {
        socket.emit('startGame', 'get cards');
    }

    function updateCard(allCards) {
        setallCards(allCards);
    }
    function joinRoom() {
        socket.emit('Join-Lobby', { username, roomId });
    }
    function CreateRoom() {
        socket.emit('Create-Lobby', { username });
    }

    function cardCheck(index, hidden, guess) {
        socket.emit('cardCheck', { index, hidden, guess });
    }

    function verifyGuess(index, hidden, guess) {
        socket.emit('verifyGuess', { index, hidden, guess });
        setClickable(false);
    }

    function checkClickable(serverUser, user) {
        if (serverUser == user) {
            return true;
        }
        return false;
    }
    function isMobileDevice() { // remove the first return when going to production
        // return false;
        // return (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1);
        let size = window.innerWidth;
        return size < 700 ? true : false;
    };


    return (
        <Fragment>
            {
                isMobileDevice() &&
        <div className={style.matrix}>
            <p>Unfortunately it is not possible to support this format device. Please switch to a laptop or something bigger than that.</p>
            <p>If you are on a supported device, please refresh the page.</p>
        </div>
            }
            {
                !isMobileDevice() &&
        <div className={style.matrix}>
            {
                openInfo == true &&
          <div className={style.popup}>
              <div className={style.iconWrapper}>
                  <FontAwesomeIcon icon={faTimes} className={style.icon} style={{ color: 'black' }} onClick={() => setPopup(false)} />
              </div>
              <p>Matrix is game about chance. There are 49 cards displayed in a 2D matrix and the goal of the game is to open all cards. Only cards with an opened adjacent card can be used to play.</p>
              <p>The options that you have when opening a card are decided based on the amount of open adjacent cards .</p>
              <br />
              <b>Amount of open adjacent cards:</b>
              <br />
              <p><b>One:</b> If there is only one open adjacent card the prompt will ask if the value of card you choose is higher or lower than the one adjacent.</p>
              <p><b>Two:</b> When there are two open adjacent cards the prompt will ask if the the value of card you choose is within or outside the values of the adjacent cards</p>
              <p><b>Three:</b> When there are three open adjacent cards the prompt will ask if the sign of the current card is already present among the adjacent cards or not.</p>
              <p><b>Four:</b> When there are four open adjacent cards the prompt will ask you to declare the exact sign of the card that is about to be turned around.</p>
              <br />
              <b>What to do when the prompt is answered incorectly?</b>
              <p>When the answer is incorect the player that chose last has to drink the amount of sips that correspond to the amount of open cards in a vertical and horizontal line from the last opened card.</p>
              <br />
              <b>What to do when the prompt is answered corectly?</b>
              <p>After three consecutive correct turns the game will automatically pass the turn to the next player</p>
        
          </div>
            }
            <div className={style.topBar}>
                <div className={style.leftWrapper}>
                    {joinedRoom && <div><FontAwesomeIcon icon={faInfoCircle} className={style.icon} onClick={() => setPopup(true)} /></div>}
                    {joinedRoom && <div className={style.roomId}>{roomId}</div>}
                </div>
                {joinedRoom && !gameStarted && <div className={style.roomId} onClick={startGame}>Start game</div>}
                <div className={style.usernames}>
                    {users && users.map((user, index) => {
                        if (user.username === username) {
                            return <div className={style.roomId} style={{ background: `${color[index]}` }}>
                                <FontAwesomeIcon icon={faUser} style={{ color: 'white', marginRight: '5px' }} />
                                <p>{user.username}</p>
                            </div>;
                        }
                        return <div className={style.roomId} key={index} style={{ background: `${color[index]}` }}><p>{user.username}</p></div>;
                    })}
                </div>
            </div>
            {
                !joinedRoom &&
          <div className={style.text} style={username ? { background: 'none' } : {}}>

              <div className={style.username}>
                  {<h1>Username</h1>}
                  <input value={username} placeholder="Type name" minLength="4" maxLength="8" onChange={(e) => setUsername(e.target.value)} style={{ background: 'whitesmoke', border: '2px solid #93CC58' }} />
              </div>

          </div>
            }
            {
                !gameStarted && !joinedRoom &&
          <div className={style.buttons}>
              <div className={style.leftButtons} style={!username ? { background: 'none' } : {}}>
                  {username && <h1>Create a room</h1>}
                  {username && <div className={style.roomId} style={{ color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={CreateRoom}>Create room</div>}
              </div>
              <div className={style.rightButtons} style={!username ? { background: 'none' } : {}}>
                  {
                      username && <h1>Join a room</h1>
                  }
                  {username && <input value={roomId} placeholder="Type Room ID" style={{ background: 'whitesmoke', border: '2px solid #93CC58', color: '#93CC58' }} onChange={(e) => setRoomId(e.target.value)} />}
                  {username && <button onClick={joinRoom}>Join room</button>}
              </div>
          </div>
            }
            <div className={style.centerWrapper}>
                <div className={style.cardDeck}>
                    {
                        allCards.map((card, index) => {
                            if (index < 49) {

                                return <Card key={card.id} hidden={card.hidden} clickable={clickable} card={card} index={index} selected={currentCard == index ? true : false} cardCheck={cardCheck} reset={resetCards.includes(card.id)} verifyGuess={verifyGuess} guessType={guessType} />;
                            }
                            return null;
                        })
                    }
                </div>
                <div className={style.resultWrapper}>
                    {gameStarted && <p>Current player:</p>}
                    {gameStarted &&
              <div className={style.result} style={{ background: '#131919' }}>
                  <FontAwesomeIcon icon={faGamepad} style={{ marginRight: '10px' }} />
                  <p style={{ paddingRight: '55px' }}>{currentPlayer.username}</p>
                  <div className={style.sips}>
                      <FontAwesomeIcon icon={faCheckSquare} style={{ marginTop: '2px' }} />
                      <p>{(attempts) + '/3'}</p>
                  </div>

              </div>
                    }
                    {gameStarted && <hr style={{ width: '100%' }} />}
                    {gameStarted && <p>History:</p>}
                    {gameStarted &&
              results.map((result, index) => {
                  let showOpacity = 1 - (index / 10);
                  return <div className={style.result} key={index} style={{ background: `${result.sips == '0 sips' ? '#93CC58' : '#D52E21'}`, opacity: showOpacity }}>{result.name + ': ' + result.sips}</div>;
              })
                    }

                </div>
            </div>
            <div className={style.footer}>
                <a href='/'>
                    <img alt="" draggable="false" src={barLogo} className={style.footerImage} />
                </a>
            </div>
        </div>
            }
        </Fragment>
    );
}

export default Matrix;
