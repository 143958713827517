import React, { Component } from 'react';
import style from './App.module.scss';
import {
    BrowserRouter as Router,
    Route,
} from 'react-router-dom';

//Import routes
import * as ROUTES from 'utilities/routes';
import Matrix from 'pages/Matrix/Matrix';
import Home from 'pages/Home/Home';
import Data from './pages/Data/Data';
// import BeerSweeper from './pages/BeerSweeper/BeerSweeper';



class App extends Component {

    render() {
        return (
            <Router>
                <div className={style.app}>
                    {/* <Route exact path={ROUTES.BEERSWEEPER} component={BeerSweeper} /> */}
                    <Route exact path={ROUTES.MATRIX} component={Matrix} />
                    <Route exact path={ROUTES.HOME} component={Home} />
                    <Route exact path={ROUTES.DATA} component={Data} />
                </div>
            </Router>
        );
    }
}

export default App;
